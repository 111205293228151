import axios from "axios";
import {
  Message
} from "element-ui";
const api = axios.create({
  // baseURL: "http://127.0.0.1:9090/",
  timeout: 10000,
});

// 请求拦截器
api.interceptors.request.use(
  (config) => {
    config.headers["Access-Control-Allow-Origin"] = "*";
    // 在请求发送之前可以做一些处理，如添加token等
    let token = sessionStorage.getItem("Authorization");
    config.headers["Authorization"] = token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
api.interceptors.response.use(
  (res) => {
    if ("/api/api/chat/start" == res.config.url) {
      return res;
    }
    const status = Number(res.status) || 200;
    const message = res.data.msg || "后台服务走丢了,请稍后重试...";

    // 如果服务器响应非200否者默认统一处理
    if (status !== 200 || res.data.code != 200) {
      //需要登录的接口 判断是否登录过期
      if (res.data.code === 10000) {
        sessionStorage.removeItem("Authorization");
      }
      if (res.data.code === 10001 && message == "账号其他地方登录") {
        sessionStorage.removeItem("Authorization");
      }
      Message({
        message: message,
        type: "error"
      });
      return Promise.reject(new Error(message));
    }
    //token刷新
    if (
      res.headers.authorization !== undefined &&
      res.headers.authorization !== null
    ) {
      const token = res.headers.authorization;
      sessionStorage.setItem("Authorization", token);
    }
    // 对响应数据进行处理
    return res.data;
  },
  (error) => {
    // 对响应错误进行处理
    return Promise.reject(error);
  }
);

export default api;