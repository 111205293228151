<template>
  <div>
    <avue-crud
      :data="chatMenuList"
      :option="option"
      @row-save="addChatMenu"
      @row-del="delChatMenu"
      @row-update="updChatMenu"
      @refresh-change="refreshChatMenu"
      :page.sync="page"
      @on-load="onLoad"
      @search-change="queryChatMenu"
      @search-reset="resetChatMenu"
    >
      <template slot="index" slot-scope="{ index }">
        {{ index + 1 }}
      </template>
    </avue-crud>
  </div>
</template>

<script>
import {
  getChatMenuList,
  addChatMenu,
  delChatMenu,
  updChatMenu,
} from "../services/chat";
export default {
  name: "Three",
  data() {
    return {
      search: {
        name: "small",
      },
      page: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      chatMenuQuery: {
        chatMenuName: "",
        current: 1,
        size: 20,
      },
      chatMenu: {},
      chatMenuList: [],
      option: {
        rowKey: "id",
        addBtnText: "新增数据",
        searchBtnText: "查询",
        column: [
          {
            label: "序号",
            prop: "index",
            fixed: true,
            addDisplay: false,
            editDisplay: false,
          },
          {
            label: "聊天菜单",
            prop: "chatMenuName",
            type: "input",
            clearable: false,
            search: true,
          },
        ],
      },
    };
  },

  mounted() {

  },

  methods: {
    //刷新
    refreshChatMenu() {
      this.getChatMenuList();
    },
    resetChatMenu(){
      this.chatMenuQuery.chatMenuName = ""
      this.getChatMenuList();
    },
    queryChatMenu(params,done){
      this.chatMenuQuery.chatMenuName = params.chatMenuName
      this.getChatMenuList();
      done()
    },
    onLoad(page) {
      this.page.currentPage = page.currentPage;
      this.page.pageSize = page.pageSize;
      this.getChatMenuList();
    },
    //聊天菜单列表
    getChatMenuList() {
      this.chatMenuQuery.current = this.page.currentPage;
      this.chatMenuQuery.size = this.page.pageSize;
      getChatMenuList(this.chatMenuQuery)
        .then((res) => {
          this.chatMenuList = res.data.records;
          this.page.total = res.data.total;
        })
        .catch((err) => {});
    },
    //新增聊天菜单
    addChatMenu(form, done) {
      addChatMenu(form)
        .then((res) => {
          this.getChatMenuList();
          done(form);
        })
        .catch((err) => {
          done(form);
        });
    },
    //删除
    delChatMenu(form) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delChatMenu(form.id)
            .then((res) => {
              if (res.data) {
                this.getChatMenuList();
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
              }
            })
            .catch((error) => {});
        })
        .catch(() => {});
    },

    updChatMenu(form, index, done) {
      updChatMenu(form)
        .then((res) => {
          if (res.data) {
            this.getChatMenuList();
            done(form);
          }
        })
        .catch((err) => {
          done(form);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
