import Vue from "vue";
import App from "./App.vue";
//导入路由模块
import router from "./router/index";
//reset.css是重置浏览器标签的样式表
import "./assets/styles/reset.css";
import AvueUeditor from "avue-plugin-ueditor";

//需要先安装ElementUI的依赖
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import Avue from "@smallwei/avue";
import "@smallwei/avue/lib/index.css";
import highlight from "highlight.js";
//高亮
Vue.use(highlight);
Vue.directive("highlight", (el) => {
  let blocks = el.querySelectorAll("pre code");
  blocks.forEach((block) => {
    highlight.highlightBlock(block);
  });
});
//s使用avue
Vue.use(ElementUI);
Vue.use(Avue);
// 关闭生产提示信息
Vue.config.productionTip = false;
//富文本
Vue.use(AvueUeditor);
//获取VueComponent构造函数
// const vm = Vue.extend({})
//创建一个共享vc
// const globalVc = new vm();
//Vue原型对象  给vue的原型对象扩展一个x属性，x指向了这个共享对象
// Vue.prototype.x = globalVc
new Vue({
  render: (h) => {
    return h(App);
  },
  beforeCreate() {
    //改造上面注释的
    Vue.prototype.$bus = this;
  },
  //注册路由
  router: router,

  // render: h => h(App),
}).$mount("#app");
