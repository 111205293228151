//1、导入vue和router包
import Vue from "vue";
import VueRouter from "vue-router";
//2.调用Vue.use()函数，把 VueRouter 安装为 Vue 的插件
Vue.use(VueRouter);
//导入需要的组件
import ChatMenu from "../views/chatMenu.vue";
const routes = [
  //从定向
  { path: "/", redirect: "/home/homePage" },
  //在 routes 数组中，申明路由的匹配规则
  //path 表示要匹配的 hash 地址；component 表示要展示的路由组件
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login/login.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/login/register.vue"),
  },
  {
    path: "/giteeLogin",
    name: "giteeLogin",
    component: () => import("../views/login/giteeLogin.vue"),
  },
  {
    path: "*",
    name: "错误页",
    component: () => import("../views/home/notFound.vue"),
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/home/home.vue"),
    children: [
      {
        path: "homePage",
        name: "主页",
        component: () => import("../views/home/homePage.vue"),
      },
      {
        path: "user",
        name: "用户管理",
        component: () => import("../views/home/user.vue"),
      },
      {
        path: "personalCenter",
        name: "个人中心",
        component: () => import("../views/home/personalCenter.vue"),
      },
      {
        path: "menu",
        name: "菜单管理",
        component: () => import("../views/home/menu.vue"),
      },
      {
        path: "role",
        name: "角色管理",
        component: () => import("../views/home/role.vue"),
      },
      {
        path: "log",
        name: "日志管理",
        component: () => import("../views/home/log.vue"),
      },
      {
        path: "messPublish",
        name: "消息推送",
        component: () => import("../views/home/msgPublish.vue"),
      },
      {
        path: "systemMess",
        name: "系统信息",
        component: () => import("../views/home/msg.vue"),
      },
      {
        path: "serviceMonitoring",
        name: "服务监控",
        component: () => import("../views/home/serviceMonitoring.vue"),
      },
      {
        path: "redisCache",
        name: "缓存列表",
        component: () => import("../views/home/redisCache.vue"),
      },
      {
        path: "alHelper",
        name: "AI助手",
        component: () => import("../views/home/alHelper.vue"),
      },
      {
        path: "aiModel",
        name: "AI模型",
        component: () => import("../views/home/aiModel.vue"),
      },
      {
        path: "esTest",
        name: "ES测试",
        component: () => import("../views/home/esTest.vue"),
      },
    ],
    
  },
   { path: "/ChatMenu", component: ChatMenu },
];
//3、创建路由的示例对象
const router = new VueRouter({
  mode: 'history',
  routes,
});

// 全局前置守卫
router.beforeEach((to, from, next) => {
  if (to.fullPath == "/register") {
    next();
  } else {
    let token = sessionStorage.getItem("Authorization");
    if (token === null && to.path != "/login" && to.path != "/giteeLogin") {
      next("/login");
    } else {
      next();
    }
  }
});
//4、向外共享路由示例对象
export default router;
