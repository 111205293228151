import api from "../utils/request";
const baseUrl = "/api/api/chat/menu";
export function getChatMenuList(chatMenuQuery) {
  return api.post(`${baseUrl}/list`, chatMenuQuery);
}

export function addChatMenu(chatMenuAdd) {
  return api.post(`${baseUrl}/add`, chatMenuAdd);
}

export function updChatMenu(chatMenuUpd) {
  return api.post(`${baseUrl}/upd`, chatMenuUpd);
}

export function delChatMenu(id) {
  return api.get(`${baseUrl}/del`, {
    params: {
      id: id,
    },
  });
}

export function list(chatMenuId) {
  return api.get(`/api/api/chat/list`, {
    params: {
      chatMenuId: chatMenuId,
    },
  });
}

export function start(chatDto) {
  return api.post(`/api/api/chat/start`, chatDto);
}

export function tokenStatistics(type) {
  return api.get(`/api/api/chat/tokens/statistics`, {
    params: {
      type: type,
    },
  });
}
