<template>
  <div id="app">
    <!-- <router-link to="/">首页</router-link>
    <router-link to="/home/menu">聊天菜单</router-link>
    <el-button size="mini" @click="logout" v-show="isShow">登出</el-button> -->
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {};
  },
  mounted() {
  },
  computed:{
    isShow(){
      return sessionStorage.getItem('Authorization');
    }
  },
  methods: {
    logout(){
      sessionStorage.removeItem("Authorization");
      this.$router.push("/login");
    }
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
</style>
